import TerrainDistributionProvider, { ITerrainDistributions } from ".";

class MemoryTerrainDistributionProvider extends TerrainDistributionProvider {
	getTerrainDistributions(): string[] {
		return ["landscape"]
	}
	getAvailableTerrainDistributions(): ITerrainDistributions {
		return this.terrainDistributions;
	}
}

export default MemoryTerrainDistributionProvider;