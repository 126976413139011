import { GriffelStyle } from '@fluentui/react-components';

type BreakpointFunction = (
    styles: GriffelStyle
) => Record<string, GriffelStyle>;

interface IBreakpoints {
    /**
     * Small devices (landscape phones, more than 576).
     *
     * @type {BreakpointFunction}
     * @memberof IBreakpoints
     */
    s: BreakpointFunction;
    /**
     * Medium devices (tablets, more than 768).
     *
     * @type {BreakpointFunction}
     * @memberof IBreakpoints
     */
    m: BreakpointFunction;
    /**
     * Large devices (desktops, more than 992).
     *
     * @type {BreakpointFunction}
     * @memberof IBreakpoints
     */
    l: BreakpointFunction;
    /**
     * X-Large devices (large desktops, more than 1200).
     *
     * @type {BreakpointFunction}
     * @memberof IBreakpoints
     */
    xl: BreakpointFunction;
    /**
     * XX-Large devices (large desktops, more than 1400px).
     *
     * @type {BreakpointFunction}
     * @memberof IBreakpoints
     */
    xxl: BreakpointFunction;
}

const breakpoints: IBreakpoints = {
    //xs: style => {
    //    return { '@media (max-width: 575.98px)': style };
    //},
    //s: style => {
    //    return { '@media (max-width:  767.98px)': style };
    //},
    //m: style => {
    //    return { '@media (max-width:   991.98px)': style };
    //},
    //l: style => {
    //    return { '@media (max-width:    1199.98px )': style };
    //},
    //xl: style => {
    //    return { '@media (max-width:     1399.98px)': style };
    //},
    //xxl: style => {
    //    return { '@media (min-width:      1400px)': style };
    //},
    s: style => {
        return { '@media (min-width:     575.98px)': style };
    },
    m: style => {
        return { '@media (min-width:    767.98px)': style };
    },
    l: style => {
        return { '@media (min-width:   991.98px )': style };
    },
    xl: style => {
        return { '@media (min-width:  1199.98px)': style };
    },
    xxl: style => {
        return { '@media (min-width: 1399.98px)': style };
    },
};

export default breakpoints;