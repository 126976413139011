import React, { useEffect } from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import Header from '../../components/Header';
import MaterialCalculatorContent from '../../components/MaterialCalculatorContent';

const useStyles = makeStyles({
	root: {
		backgroundColor: tokens.colorNeutralBackground2
	},
	header: {
		position: 'sticky',
		top: 0,
		zIndex: 999
	},
	content: {
		...shorthands.margin('0', 'auto'),
		...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL)
	}
});

function App() {
	const classes = useStyles();
	useEffect(() => {
		let computedStyle = getComputedStyle(document.getElementById("fluentProvider")!);
		let token = tokens.colorNeutralBackground2;
		let varName = token.substring(4, token.length-1);
		let color = computedStyle.getPropertyValue(varName);
		document.body.style.backgroundColor = color;
	}, []);
	return (
		<div className={classes.root}>
			<Header className={classes.header} />
			<MaterialCalculatorContent className={classes.content} />
		</div>
	);
}

export default App;