import { Combobox, Input, makeStyles, mergeClasses, Option, shorthands, Text, useId } from "@fluentui/react-components";
import React, { forwardRef, useEffect } from "react";
import { getInchFractionLabel } from "./models/inchFraction";
import { InchFractionPrecision } from "./models/InchFractionPrecision";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		...shorthands.gap("10px")
	},
	inchRoot: {
		display: "flex",
		flexWrap: "nowrap",
		flexDirection: "row",
		...shorthands.gap("5px"),
		flexGrow: 1,
	},
	inchWholeFieldRoot: {
		minWidth: "3rem",
		flexGrow: 1,
	},
	inchWholeFieldInput: {
		width: "4rem"
	},
	inchFractionFieldRoot: {
		minWidth: "unset"
	},
	inchFractionFieldInput: {
		width: "2.5rem"
	},
	inchWholeFieldNoFeetRoot: {
		minWidth: "unset",
	},
	inchWholeFieldNoFeetInput: {
		minWidth: "6rem",
		width: "auto"
	},
	footFieldRoot: {
		flexGrow: 1,
		minWidth: "5rem",
	},
	footFieldInput: {
	}
});

export interface IImperialLengthInputProps {
	unit?: 'ft' | 'in' | 'ft. in.',
	onValueChange?: (length: number) => void,
	value?: number,
	inchFractionPrecision?: InchFractionPrecision,
	"aria-labelledby"?: string,
	id?: string,
	className?: string
}

export default function ImperialLengthInput(props: IImperialLengthInputProps) {
	const classes = useStyles();
	const ftTextId = useId("ft-text");
	const inTextId = useId("in-text");
	const inWholeInputId = useId("in-whole-input");
	const inWholeRef = React.useRef<HTMLInputElement>(null);
	const inFractRef = React.useRef<HTMLInputElement>(null);

	const unit = props.unit ? props.unit : 'ft. in.';
	const inchFractionPrecision = props.inchFractionPrecision ?? InchFractionPrecision.none;
	const defaultFt = props.value
		? unit === 'ft'
			? props.value / 12
			: Math.floor(props.value / 12)
		: 0
	const [ftValue, setFtValue] = React.useState(defaultFt);
	const [inValue, setInValue] = React.useState(props.value ? props.value % 12 : 0);
	const [inchFractionNumerator, setInchFractionNumerator] = React.useState(props.value ? props.value % 1 : 0);
	const notifyLengthChange = React.useCallback((ft: number, inch: number, inchFraction: number) => {
		if (props.onValueChange)
			props.onValueChange(ft * 12 + inch + inchFraction / inchFractionPrecision);
	}, [props, inchFractionPrecision]);

	React.useEffect(() => {
		const inchFractionPrecision = props.inchFractionPrecision ?? InchFractionPrecision.none;
		if (props.value !== ftValue * 12 + inValue + inchFractionNumerator / inchFractionPrecision) {
			if (props.value || props.value === 0) {
				const unit = props.unit ?? 'ft. in.';
				let inches = 0;
				switch (unit) {
					case 'ft':
						setFtValue(props.value / 12);
						break;
					case 'in':
						inches = props.value;
						break;
					case 'ft. in.':
						inches = props.value % 12;
						setFtValue(Math.floor(props.value / 12));
						break;
				}
				if (inchFractionPrecision === InchFractionPrecision.none)
					setInchFractionNumerator(0);
				else {
					inches = Math.floor(inches);
					const fraction = props.value % 1;
					const fractionNumerator = Math.round(fraction * inchFractionPrecision);
					setInchFractionNumerator(fractionNumerator);
				}
				setInValue(inches);
			}
		}
	}, [props.value, props.unit, props.inchFractionPrecision, ftValue, inValue, inchFractionNumerator]);

	const handleFtChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		let feet = parseFloat(e.target.value);
		feet = Number.isNaN(feet) || feet < 0 ? 0 : feet;
		notifyLengthChange(feet, inValue, inchFractionNumerator);
		setFtValue(feet);
	}, [notifyLengthChange, inValue, inchFractionNumerator]);

	const handleFtKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (["Enter", " "].indexOf(e.key) >= 0) {
			e.preventDefault();
			e.stopPropagation();
			inWholeRef.current?.focus();
		}
	}, []);
	const handleInKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (["Enter", " "].indexOf(e.key) >= 0) {
			e.preventDefault();
			e.stopPropagation();
			inFractRef.current?.focus();
		}
	}, []);

	const allowDigitsOnlyIn = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (inchFractionPrecision !== InchFractionPrecision.none && unit !== 'ft') {
			if (e.key < "0" || e.key > "9") {
				e.preventDefault();
				e.stopPropagation();
			}
		}
	}, [unit, inchFractionPrecision]);
	const allowDigitsOnlyFt = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (unit !== 'ft')
			if (e.key < "0" || e.key > "9") {
				e.preventDefault();
				e.stopPropagation();
			}
	}, [unit]);

	const handleInChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		let inches = parseFloat(e.target.value);
		inches = Number.isNaN(inches) || inches < 0 ? 0 : inches;
		notifyLengthChange(ftValue, inches, inchFractionNumerator);
		setInValue(inches);
	}, [notifyLengthChange, ftValue, inchFractionNumerator]);

	const handleInBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		if (inValue > 12 && unit === 'ft. in.') {
			setFtValue(ftValue + Math.floor(inValue / 12));
			setInValue(inValue % 12);
		}
	}, [unit, inValue, ftValue]);

	const handleNumeratorChange = React.useCallback((numerator: number) => {
		notifyLengthChange(ftValue, inValue, numerator);
		setInchFractionNumerator(numerator);
	}, [notifyLengthChange, ftValue, inValue]);

	return <div aria-labelledby={props["aria-labelledby"]} className={mergeClasses(classes.root, props.className)} id={props.id} >
		{
			unit !== 'in' &&
			<Input
				className={classes.footFieldRoot}
				input={{ className: classes.footFieldInput }}
				type="number"
				value={ftValue ? ftValue.toString() : ""}
				onChange={handleFtChange}
				onKeyDown={handleFtKeyDown}
				onKeyPress={allowDigitsOnlyFt}
				contentAfter={
					<Text size={400} id={ftTextId}>ft.</Text>
				}
			/>
		}
		{
			unit !== 'ft' &&
			<div className={classes.inchRoot}>
				<Input id={inWholeInputId} ref={inWholeRef}
					className={mergeClasses(classes.inchWholeFieldRoot, unit === 'in' ? classes.inchWholeFieldNoFeetRoot : undefined)}
					input={{ className: unit !== 'in' ? classes.inchWholeFieldInput : classes.inchWholeFieldNoFeetInput }}
					type="number"
					value={inValue ? inValue.toString() : ""}
					onChange={handleInChange}
					onKeyDown={handleInKeyDown}
					onKeyPress={allowDigitsOnlyIn}
					onBlur={handleInBlur}
					contentAfter={
						<Text size={400} id={inTextId}>in.</Text>
					}
				/>
				{
					inchFractionPrecision !== InchFractionPrecision.none &&
					<InchFractionSelector ref={inFractRef}
						inchFractionPrecision={inchFractionPrecision}
						numerator={inchFractionNumerator}
						onNumeratorChange={handleNumeratorChange} />
				}
			</div>
		}
	</div>
}

interface IInchFractionSelectorProps {
	inchFractionPrecision?: InchFractionPrecision,
	numerator?: number,
	onNumeratorChange: (numerator: number) => void
}

declare type OptionOnSelectData = {
	optionValue: string | undefined;
	optionText: string | undefined;
	selectedOptions: string[];
};

const InchFractionSelector = forwardRef((props: IInchFractionSelectorProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const classes = useStyles();
	const inchFractionPrecision = props.inchFractionPrecision ? props.inchFractionPrecision : InchFractionPrecision.none;
	const [numerator, setNumerator] = React.useState(props.numerator ? props.numerator : inchFractionPrecision);
	const fractionOptions = React.useMemo(() => {
		let options: { numerator: number, displayText: string }[] = [];
		for (let i = 0; i < inchFractionPrecision; i++) {
			options.push({ numerator: i, displayText: getInchFractionLabel(i / inchFractionPrecision, inchFractionPrecision) });
		}
		return options;
	}, [inchFractionPrecision]);

	useEffect(() => {
		setNumerator(props.numerator ?? inchFractionPrecision);
	}, [props.numerator, inchFractionPrecision]);

	const handleNumeratorChange = React.useCallback((e: any, data: OptionOnSelectData) => {
		let numerator = parseInt(data.optionValue ?? "");
		numerator = Number.isNaN(numerator) || numerator < 0 ? inchFractionPrecision : numerator;
		setNumerator(numerator);
		props.onNumeratorChange(numerator);
	}, [props, inchFractionPrecision]);

	return <Combobox ref={ref}
		className={classes.inchFractionFieldRoot}
		input={{ className: classes.inchFractionFieldInput }}
		value={fractionOptions[numerator]?.displayText ?? ""}
		selectedOptions={[numerator.toString()]}
		onOptionSelect={handleNumeratorChange}>
		{
			fractionOptions.map((option, index) => <Option key={option.numerator} value={option.numerator.toString()}>{option.displayText}</Option>)
		}
	</Combobox>
});