import { Dropdown, Label, makeStyles, mergeClasses, Option, useId } from "@fluentui/react-components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DependencyInjectionContext } from "../../../../services/DependencyInjectionProvider";
import { OptionOnSelectData } from "../../../Shared/Models/OptionOnSelectData";
import ITerrainsProps from "./Shared/ITerrainsProps";
import ShapeInput, { AreaCalculationType, AreaType } from "./Shared/ShapeInput";
import '../../../../styles/styles.css'
import { MaterialsContext } from "../../../../services/Context/MaterialContext";
import { LandscapeTerrainValues, TerrainType } from "../../../../services/Context/MaterialContext/FieldTypes";
import { PebbleSizeDescription } from "../../../../services/Providers/PebbleSizeProvider/PebbleSizeProvider";

const useStyles = makeStyles({
	fillTypeContainer: {
	},
	fillTypeCombobox: {
	},
	pebbleSizeCombobox: {
	},
	depthInputContainer: {
	},
	depthCombobox: {
	}
});

function LandscapeTerrain(props: ITerrainsProps) {
	const dependencyInjectionContext = useContext(DependencyInjectionContext);
	const materialContext = useContext(MaterialsContext);
	const terrainValues = materialContext?.terrainValue as LandscapeTerrainValues;
	const classes = useStyles();

	const pebbleSizesProvider = dependencyInjectionContext?.pebbleSizeProvider;
	const fillTypeProvider = dependencyInjectionContext?.fillTypeProvider;
	const depthProvider = dependencyInjectionContext?.depthProvider;

	const availablePebbleSizes = useMemo(() => pebbleSizesProvider?.getPebbleSizes() ?? [], [pebbleSizesProvider]);
	const availableFillTypes = useMemo(() => fillTypeProvider?.getAvailableFillTypes(), [fillTypeProvider]);
	const fillTypes = useMemo(() => fillTypeProvider?.getFillTypes() ?? [], [fillTypeProvider]);
	const availableDepths = useMemo(() => depthProvider?.getDepths() ?? [], [depthProvider]);
	const defaultFillType = useMemo(() => fillTypes?.indexOf("gravel") !== -1 ? "gravel" : fillTypes[0], [fillTypes]);
	const [selectedFillType, setSelectedFillType] = useState<string>(defaultFillType);
	const defaultPebbleSize = useMemo(() => availablePebbleSizes.filter(s => s.maxSize <= 2).at(-1) ?? availablePebbleSizes[0], [availablePebbleSizes]);
	const [selectedPebbleSize, setSelectedPebbleSize] = useState<PebbleSizeDescription>(defaultPebbleSize);
	const defaultDepth = useMemo(() => availableDepths.indexOf(2) === -1 ? availableDepths[0] : 2, [availableDepths]);
	const [depth, setDepth] = useState<number>(defaultDepth);
	const getWeightFactor = (fillType: string, gravelPebble: PebbleSizeDescription) => {
		let weightFactor;
		switch (fillType) {
			case "gravel":
				weightFactor = gravelPebble.weightFactor;
				break;
			case "sand":
				// conversion from cu. ft. to tons
				// cu. ft. / 27 = cu. yd.
				// cu.yd. / 1.25 (avg. sand density) = tons.
				weightFactor = 1.25 / 27;
				break;
			case "soil":
				weightFactor = undefined;
				break;
			default:
		}
		return weightFactor;
	};

	const fillTypeLabelId = useId("fillType");
	const pebbleSizeId = useId("pebbleSize");
	const depthLabelId = useId("depthLabel");
	const depthInputId = useId("depthInput");
	useEffect(() => {
		if (materialContext?.terrainType !== TerrainType.Landscape) {
			materialContext?.setTerrainValue(({
				...new LandscapeTerrainValues(),
				depth: defaultDepth / 12,
				fillType: defaultFillType,
				pebbleDescription: defaultPebbleSize,
				weightFactor: getWeightFactor(defaultFillType, selectedPebbleSize)
			}));
			materialContext?.setTerrainType(TerrainType.Landscape);
		}
	}, []);

	useEffect(() => {
		const terrainValues = materialContext?.terrainValue as LandscapeTerrainValues;
		const canCalculate = terrainValues.area > 0 && terrainValues.depth > 0 && (terrainValues.fillType === "soil" || terrainValues.weightFactor);
		props.onCanCalculateMaterialsChanged(!!canCalculate);
	}, [props.onCanCalculateMaterialsChanged, materialContext?.terrainValue])

	useEffect(() => {
		setDepth(((materialContext?.terrainValue as LandscapeTerrainValues).depth * 12 || undefined) ?? defaultDepth);
		const pebbleSize = availablePebbleSizes.find(pSize => pSize.maxSize === (materialContext?.terrainValue as LandscapeTerrainValues).pebbleDescription?.maxSize);
		setSelectedPebbleSize(pebbleSize ?? defaultPebbleSize);
		setSelectedFillType(((materialContext?.terrainValue as LandscapeTerrainValues).fillType || undefined) ?? defaultFillType);
	}, [materialContext, defaultDepth, availablePebbleSizes, defaultPebbleSize, defaultFillType]);	

	const renderFillTypeOptions = useMemo(() => {
		return fillTypes.map((key) => {
			return <Option key={key} value={key}>{availableFillTypes![key].display}</Option>
		})
	}, [fillTypes, availableFillTypes]);
	const changeFillType = useCallback((e: any, data: OptionOnSelectData) => {
		setSelectedFillType(data?.optionValue!);
		materialContext?.setTerrainValue(previous => ({ ...previous, fillType: data.optionValue!, weightFactor: getWeightFactor(data.optionValue!, selectedPebbleSize) }));
	}, [materialContext?.terrainValue, selectedPebbleSize]);

	const renderPebbleSizeOptions = useMemo(() => {
		return availablePebbleSizes.map(pSize =>
			<Option key={pSize.display} value={pSize.display}>{pSize.display}</Option>
		);
	}, [availablePebbleSizes]);
	const changePebbleSize = useCallback((e: any, data: OptionOnSelectData) => {
		const pebbleSize = availablePebbleSizes.find(pSize => pSize.display === data.optionValue)!;
		setSelectedPebbleSize(pebbleSize);
		materialContext?.setTerrainValue(previous => ({ ...previous, pebbleDescription: pebbleSize, weightFactor: pebbleSize.weightFactor }));
	}, [materialContext?.terrainValue, availablePebbleSizes]);

	const renderDepthOptions = useMemo(() => {
		return availableDepths.map(depth =>
			<Option key={depth.toString()} value={depth.toString()} text={`${depth}''`}>{`${depth}''`}</Option>
		);
	}, [availableDepths]);

	const changeDepth = useCallback((e: any, data: OptionOnSelectData) => {
		const depth = parseFloat(data.optionValue!);
		setDepth(depth);
		materialContext?.setTerrainValue(previous => ({ ...previous, depth: depth / 12}));
	}, []);

	const changeArea = useCallback((area: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, area: area / 144}));
	}, []);

	const changeShape = useCallback((shape: AreaType) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, shapeType: shape }));
	}, []);

	const changeShapeParams = useCallback((params: number[]) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, shapeValue: params }));
	}, []);

	const changeAreaMode = useCallback((mode: AreaCalculationType) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, areaCalculationMode: mode }));
	}, []);


	return (
		<>
			<div className={mergeClasses("form-input-container", classes.fillTypeContainer)}>
				<Label id={fillTypeLabelId} weight="semibold" className="form-input-label">Fill Type</Label>
				<Dropdown
					aria-labelledby={fillTypeLabelId}
					className={mergeClasses("form-input", classes.fillTypeCombobox)}
					placeholder="Select a fill type"
					onOptionSelect={changeFillType}
					value={availableFillTypes?.[selectedFillType].display}
				>
					{renderFillTypeOptions}
				</Dropdown>
			</div>
			{
				selectedFillType === "gravel" &&
				<div className={mergeClasses("form-input-container", classes.fillTypeContainer)}>
					<Label id={pebbleSizeId} weight="semibold" className="form-input-label">Pebble/Gravel size</Label>
					<Dropdown
						aria-labelledby={pebbleSizeId}
						className={mergeClasses("form-input", classes.pebbleSizeCombobox)}
						placeholder="Select a size"
						onOptionSelect={changePebbleSize}
						value={selectedPebbleSize.display}
					>
						{renderPebbleSizeOptions}
					</Dropdown>
				</div>
			}
			<div className={mergeClasses("form-input-container", classes.depthInputContainer)}>
				<Label id={depthLabelId} htmlFor={depthInputId} weight="semibold" className="form-input-label">Depth</Label>
				<Dropdown
					aria-labelledby={depthLabelId}
					className={mergeClasses("form-input", classes.depthCombobox)}
					placeholder="Select a depth"
					onOptionSelect={changeDepth}
					value={`${depth}''`}
				>
					{renderDepthOptions}
				</Dropdown>
			</div>
			<ShapeInput
				shapeParams={terrainValues.shapeValue}
				shape={terrainValues.shapeType}
				calculationType={terrainValues.areaCalculationMode}
				onAreaChange={changeArea}
				onShapeParamsChange={changeShapeParams}
				onCalculationTypeChange={changeAreaMode}
				onShapeChange={changeShape} />
		</>
	);
}

export default LandscapeTerrain;