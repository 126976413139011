//import { ParialTheme, createTheme } from '@fluentui/react-components';
import { createLightTheme, createDarkTheme, Theme, BrandVariants } from '@fluentui/react-components';

const olimarTheme: BrandVariants = {
	10: "#090102",
	20: "#2F0911",
	30: "#4F0219",
	40: "#66001F",
	50: "#7D0024",
	60: "#940028",
	70: "#AB0A2C",
	80: "#BE2030",
	90: "#CE3436",
	100: "#DD483E",
	110: "#EA5D47",
	120: "#F57253",
	130: "#FE8762",
	140: "#FF9F7A",
	150: "#FFB695",
	160: "#FFCBB1"
};

const lightTheme: Theme = {
	...createLightTheme(olimarTheme),
};

const darkTheme: Theme = {
	...createDarkTheme(olimarTheme),
};

darkTheme.colorBrandForeground1 = olimarTheme[110];
darkTheme.colorBrandForeground2 = olimarTheme[120];

export { lightTheme, darkTheme };