import MaterialUnit from "../../../components/Shared/Models/MaterialUnit";
import BaseTerrainMaterialCalculator, { Material } from "./BaseTerrain";

interface LandscapeDimensions {
	/**
	 * Square footage to be covered
	 */
	area: number,

	/**
	 * Depth of the terrain in feet
	 */
	depth: number,

	/**
	 * Cubic feet of material per ton
	 */
	weightFactor?: number
}

export default class LandscapeMaterialsCalculator implements BaseTerrainMaterialCalculator {
	constructor(private defaultDimensions: LandscapeDimensions) {
	}

	calculate(params?: Partial<LandscapeDimensions>): Material {
		params = params || {};
		const dimensions = this.defaultDimensions = {
			...this.defaultDimensions,
			...params
		};
		const cubicFeet = dimensions.area * dimensions.depth;
		if (dimensions.weightFactor) {

			const tons = cubicFeet * dimensions.weightFactor;
			return {
				unit: MaterialUnit.Pound,
				value: tons * 2000
			};
		}

		return {
			unit: MaterialUnit.CubicFeet,
			value: cubicFeet
		};
	}
}