import { Combobox, Dropdown, Input, InputProps, Label, makeStyles, mergeClasses, Option, Radio, RadioGroup, RadioGroupOnChangeData, useId } from "@fluentui/react-components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ImperialLengthInput from "../../../../Shared/ImperialLengthInput";
import { InchFractionPrecision } from "../../../../Shared/ImperialLengthInput/models/InchFractionPrecision";
import { OptionOnSelectData } from "../../../../Shared/Models/OptionOnSelectData";
import '../../../../../styles/styles.css'
import { MaterialsContext } from "../../../../../services/Context/MaterialContext";
import { Terrain, TerrainType } from "../../../../../services/Context/MaterialContext/FieldTypes";

interface IShapeInputProps {
	className?: string;
	shapeParams?: number[];
	shape?: AreaType,
	calculationType?: AreaCalculationType,
	onAreaChange?: (area: number) => void;
	onShapeParamsChange?: (shapeParams: number[]) => void;
	onShapeChange?: (shape: AreaType) => void;
	onCalculationTypeChange?: (calculationType: AreaCalculationType) => void;
}

const useStyles = makeStyles({
	shapeSelectorContainer: {
	},
	shapeSelectorCombobox: {
	},
});

export enum AreaType {
	//Manually = "Manually",
	Square = "Square",
	Rectangle = "Rectangle",
	Circle = "Circle",
	RightTriangle = "Right Triangle"
}

export type AreaCalculationType = "manually" | "shape";
function ShapeInput(props: IShapeInputProps) {

	const areaLabelId = useId("areaLabel");
	const shapeTypeLabelId = useId("shapeLabel");
	const input1Id = useId("input1");
	const input2Id = useId("input2");

	const classes = useStyles();

	const [areaCalculationType, setAreaCalculationType] = useState<AreaCalculationType>("manually");
	const [areaType, setAreaType] = useState<AreaType | undefined>(AreaType.Rectangle);
	const [shapeParams, setShapeParams] = useState<number[]>(props.shapeParams?? [0, 0]);

	useEffect(() => {
		if (props.calculationType)
			setAreaCalculationType(props.calculationType);
	}, [props.calculationType]);
	useEffect(() => {
		if (props.shape)
			setAreaType(props.shape);
	}, [props.shape]);
	useEffect(() => {
		if (props.shapeParams)
			setShapeParams(props.shapeParams);
	}, [props.shapeParams]);

	useEffect(() => {
		let area = 0;
		if (props.calculationType === "manually")
			area = shapeParams[0] * shapeParams[1];
		else {
			switch (props.shape) {
				case AreaType.Rectangle:
				case AreaType.Square:
					area = shapeParams[0] * shapeParams[1];
					break;
				case AreaType.RightTriangle:
					area = shapeParams[0] * shapeParams[1] / 2;
					break;
				case AreaType.Circle:
					area = shapeParams[0] * shapeParams[0] * Math.PI / 4;
					break;
			}
		}
		props.onAreaChange?.(area);
	}, [props.onAreaChange, props.calculationType, props.shape, props.shapeParams]);


	const changeShapeParams = useCallback((params: number[]) => {
		setShapeParams(params);
		props.onShapeParamsChange?.(params);
	}, [props.onShapeParamsChange]);

	const changeShape = useCallback((e: any, data: OptionOnSelectData) => {
		const shape = data?.optionValue as AreaType;
		setAreaType(shape);
		props.onShapeChange?.(shape);
	}, [props.onShapeChange]);

	const changeMode = useCallback((_: any, data: RadioGroupOnChangeData) => {
		const mode = data.value as AreaCalculationType;
		setAreaCalculationType(mode);
		props.onCalculationTypeChange?.(mode);
	}, [props.onCalculationTypeChange]);

	const renderShapeOptions = useMemo(() => {
		return Object.values(AreaType).map((key, index) => {
			return <Option key={index} value={key}>{key}</Option>
		})
	}, []);

	const renderRectangularShapeInput = () => {
		return (
			<>
				<div className="form-input-container">
					<Label htmlFor={input1Id} weight="semibold" className="form-input-label">Width</Label>
					<ImperialLengthInput id={input1Id}
						className="form-input"
						value={shapeParams[0]}
						onValueChange={(value: number) => changeShapeParams([value, shapeParams[1]])}
						unit='ft' />
				</div>
				<div className="form-input-container">
					<Label htmlFor={input2Id} weight="semibold" className="form-input-label">Length</Label>
					<ImperialLengthInput id={input2Id}
						className="form-input"
						value={shapeParams[1]}
						onValueChange={(value: number) => changeShapeParams([shapeParams[0], value])}
						unit='ft' />
				</div>
			</>
		);
	};
	const renderSquareShapeInput = () => {
		return (
			<>
				<div className="form-input-container">
					<Label htmlFor={input1Id} weight="semibold" className="form-input-label">Side</Label>
					<ImperialLengthInput id={input1Id}
						className="form-input"
						value={shapeParams[0]}
						onValueChange={(value: number) => changeShapeParams([value, value])}
						unit='ft' />
				</div>
			</>
		);
	};
	const renderCircularShapeInput = () => {
		return (
			<>
				<div className="form-input-container">
					<Label htmlFor={input1Id} weight="semibold" className="form-input-label">Diameter</Label>
					<ImperialLengthInput id={input1Id}
						className="form-input"
						value={shapeParams[0]}
						onValueChange={(value: number) => changeShapeParams([value, 0])}
						unit='ft' />
				</div>
			</>
		);
	};
	const renderManuallyAreaInput = () => {
		return (
			<>
				<div className="form-input-container">
					<Label htmlFor={input1Id} weight="semibold" className="form-input-label">Square Feet</Label>
					<Input id={input1Id}
						className="form-input"
						value={(shapeParams[0] / 12).toString()}
						type="number"
						onChange={(ev, value) => changeShapeParams([Number(value.value) * 12, 12])}
					/>
				</div>
			</>
		);
	}

	const renderShapeInput = () => {
		if (areaType === AreaType.Rectangle || areaType === AreaType.RightTriangle) {
			return renderRectangularShapeInput();
		} else if (areaType === AreaType.Square) {
			return renderSquareShapeInput();
		} else if (areaType === AreaType.Circle) {
			return renderCircularShapeInput();
		} else {
			return <>No Shape Selected</>
		}
	};


	return (
		<>
			<div className={mergeClasses("form-input-container", classes.shapeSelectorContainer)}>
				<Label id={areaLabelId} weight="semibold" className="form-input-label">Area</Label>
				<RadioGroup aria-labelledby={areaLabelId}
					value={areaCalculationType}
					onChange={changeMode}
				>
					<Radio value="manually" label="Enter manually" />
					<Radio value="shape" label="Calculate from shape"/>
				</RadioGroup>
				{
					areaCalculationType === "manually" && renderManuallyAreaInput()
				}
				{
					areaCalculationType === "shape" && <>
						<Label id={shapeTypeLabelId} weight="semibold" className="form-input-label">Shape</Label>
						<Dropdown
							aria-labelledby={shapeTypeLabelId}
							className={mergeClasses("form-input", classes.shapeSelectorCombobox)}
							placeholder="Select a shape"
							onOptionSelect={changeShape}
							value={areaType}
						>
							{renderShapeOptions}
						</Dropdown>
						{ shapeParams && renderShapeInput() }
					</>
				}
			</div>
		</>
	);
}

export default ShapeInput;