import { Subtitle1 } from "@fluentui/react-components";
import { tokens } from '@fluentui/react-theme';
import { useCallback, useContext, useMemo } from "react";
import { MaterialsContext } from "../../../services/Context/MaterialContext";
import { LandscapeTerrainValues, TerrainType } from "../../../services/Context/MaterialContext/FieldTypes";
import LandscapeMaterialsCalculator from "../../../services/Services/TerrainMaterialsCalculator/LandscapeTerrain";
import { DependencyInjectionContext } from "../../../services/DependencyInjectionProvider";
import { calcRequiredBags } from "../../../utils/RequiredMaterials";
import MaterialCoverageTable from "../../Shared/MaterialCoverageTable/MaterialCoverageTable";

export interface ILandscapeMaterialTableProps {
}

export default function LandscapeMaterialTable(props: ILandscapeMaterialTableProps) {
	const materialContext = useContext(MaterialsContext);
	const dependencyInjectionContext = useContext(DependencyInjectionContext);

	const depthProvider = dependencyInjectionContext?.depthProvider;
	const areaProvider = dependencyInjectionContext?.areaProvider;
	const bagsProvider = dependencyInjectionContext?.bagsProvider;

	const weightFactor = (materialContext?.terrainValue as LandscapeTerrainValues).weightFactor;
	const depths = useMemo(() => depthProvider?.getDepths() ?? [], [depthProvider]);
	const areas = useMemo(() => areaProvider?.getAreas() ?? [], [areaProvider]);
	const availablesBags = useMemo(() => bagsProvider?.getBagsTypes() ?? [], [bagsProvider]);

	const tableTitle = useMemo(() => {
		let needed: string = "";
		if (materialContext?.materialsResult === "raw")
			needed = "Amount of tons";
		else if (weightFactor) {
			needed = `Number of ${availablesBags.length === 1 ? availablesBags[0].display : ""} bags`;
		}
		else
			needed = "Cubic feets";
		return needed + " needed to cover specified area and depth"
	}, [availablesBags, weightFactor, materialContext?.materialsResult]);

	const landscapeMaterialCalculator = useMemo(() => {
		if (materialContext?.terrainType === TerrainType.Landscape) {
			const value = materialContext.terrainValue as LandscapeTerrainValues;
			return new LandscapeMaterialsCalculator({ area: value.area, depth: value.depth, weightFactor: value.weightFactor });
		}
	}, [materialContext?.terrainType, materialContext?.terrainValue]);

	const materialCalculator = useCallback((depth: number, area: number) => {
		return landscapeMaterialCalculator?.calculate({ area: area, depth: depth / 12 }).value ?? 0;
	}, [landscapeMaterialCalculator]);
	return <>
		<div style={{ marginBottom: tokens.spacingHorizontalM }}>
			<Subtitle1 align="center" block>{tableTitle}</Subtitle1>
		</div>
		<MaterialCoverageTable
			depths={depths}
			areas={areas}
			calculateValue={materialCalculator}
			format={{
				columnHeader: v => v + " sq.\u00A0ft",
				rowHeader: v => v + "''",
				body: v => {
					if (materialContext?.materialsResult === "raw")
						return (v / 2000).toFixed(2);
					if (weightFactor) {
						const requiredBags = calcRequiredBags(availablesBags, v);
						if (availablesBags.length === 1)
							return requiredBags[0].count.toString();
						return requiredBags.map(b => `${b.count} (${b.bag.display})`).join("\n");
					}
					return v.toFixed(2);
				}
			}}
		/>
	</>
}