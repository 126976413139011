import { createContext, useMemo } from "react";
import BagsProvider from "./Providers/BagsProvider";
import MemoryBagsProvider from "./Providers/BagsProvider/MemoryBagsProvider";
import FillTypeProvider from "./Providers/FillTypeProvider";
import MemoryFillTypeProvider from "./Providers/FillTypeProvider/MemoryFillTypeProvider";
import TerrainDistributionProvider from "./Providers/TerrainDistributionProvider";
import MemoryTerrainDistributionProvider from "./Providers/TerrainDistributionProvider/MemoryTerrainDistributionProvider";
import MaterialsProvider from "./Context/MaterialContext";
import MemoryPebbleSizeProvider from "./Providers/PebbleSizeProvider/MemoryPebbleSizeProvider";
import PebbleSizeProvider from "./Providers/PebbleSizeProvider/PebbleSizeProvider";
import DepthProvider from "./Providers/DepthProvider/DepthProvider";
import AreaProvider from "./Providers/AreaProvider/AreaProvider";
import MemoryDepthProvider from "./Providers/DepthProvider/MemoryDepthProvider";
import MemoryAreaProvider from "./Providers/AreaProvider/MemoryAreaProvider";

interface IDependencyInjectionProviderProps {
	children: JSX.Element
}

interface IDependencyInjectionContext {
	terrainDistributionProvider: TerrainDistributionProvider,
	fillTypeProvider: FillTypeProvider,
	bagsProvider: BagsProvider
	pebbleSizeProvider: PebbleSizeProvider,
	depthProvider: DepthProvider,
	areaProvider: AreaProvider
}

export const DependencyInjectionContext = createContext<IDependencyInjectionContext | null>(null);

function DependencyInjectionProvider(props: IDependencyInjectionProviderProps) {

	const dependencyInjectionContextValue: IDependencyInjectionContext = useMemo(() => ({
		terrainDistributionProvider: new MemoryTerrainDistributionProvider(),
		fillTypeProvider: new MemoryFillTypeProvider(),
		bagsProvider: new MemoryBagsProvider(),
		pebbleSizeProvider: new MemoryPebbleSizeProvider(),
		depthProvider: new MemoryDepthProvider(),
		areaProvider: new MemoryAreaProvider()
	}), []);
	return (
		<DependencyInjectionContext.Provider value={dependencyInjectionContextValue}>
			<MaterialsProvider>
				{props.children}
			</MaterialsProvider>
		</DependencyInjectionContext.Provider>
	);
}
export default DependencyInjectionProvider;