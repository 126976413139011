import BagsProvider, { BagType } from ".";

class MemoryBagsProvider extends BagsProvider {
	getBagsTypes(): BagType[] {
		return [
			//{
			//	display: "1.5 T",
			//	weight: 3000
			//},
			{
				display: "40 Lbs",
				weight: 40
			}]
	}
}

export default MemoryBagsProvider;