import { AreaCalculationType, AreaType } from "../../../components/MaterialCalculatorContent/TerrainInput/Terrains/Shared/ShapeInput";
import { PebbleSizeDescription } from "../../Providers/PebbleSizeProvider/PebbleSizeProvider";

export class StackedTerrainValues {
	avgWidth: number = 0;
	avgHeight: number = 0;
	avgLength: number = 0;
	coverage: number = 15;
}

export class FlatworkTerrainValues {
	coverage: number = 120;
	shapeValue: number[] = [0, 0];
	area: number = 0;
	shapeType: AreaType = AreaType.Rectangle;
	areaCalculationMode: AreaCalculationType = "manually";
}

export class LandscapeTerrainValues {
	depth: number = 0;
	pebbleDescription?: PebbleSizeDescription;
	fillType: "gravel" | "sand" | "soil" | "" = "";
	area: number = 0;
	weightFactor?: number = 0;
	shapeValue: number[] = [0, 0];
	shapeType: AreaType = AreaType.Rectangle;
	areaCalculationMode: AreaCalculationType = "manually";
}

export type Terrain = StackedTerrainValues | FlatworkTerrainValues | LandscapeTerrainValues;
export enum TerrainType {
	Landscape,
	Flatwork,
	Stacked,
}

export type MaterialResult = "raw" | "packaged";