import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { FluentProvider } from '@fluentui/react-components';
import { lightTheme } from './styles/lightTheme';
import DependencyInjectionProvider from './services/DependencyInjectionProvider';
import { TITLE } from './config/constants';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

document.title = TITLE;

root.render(
	<React.StrictMode>
		<FluentProvider theme={lightTheme} id="fluentProvider">
			<DependencyInjectionProvider>
				<App />
			</DependencyInjectionProvider>
		</FluentProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();