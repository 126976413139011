import DepthProvider from "./DepthProvider";

export default class MemoryDepthProvider extends DepthProvider {
	getDepths(): number[] {
		return [
			1.5,
			2.0,
			2.5,
			3.0,
			4.0
		]
	}
}