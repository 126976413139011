export interface IFillType {
	display: "Pebble/Gravel" | "Sand" | "Soil",
	/**
	 * Tons per cubic yard
	 */
	weightFactor?: number
}
abstract class FillTypeProvider {
	protected fillTypes: { [key: string]: IFillType } = {
		gravel: {
			display: "Pebble/Gravel",
			weightFactor: 1.25
		},
		sand: {
			display: "Sand",
			weightFactor: 1.25
		},
		soil: {
			display: "Soil"
		}
	}
	abstract getFillTypes(): string[]
	abstract getAvailableFillTypes(): { [key: string]: IFillType }
}

export default FillTypeProvider;