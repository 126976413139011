export enum InchFractionPrecision {
	'none' = 1,
	'1/2' = 2,
	'1/4' = 4,
	'1/8' = 8,
	'1/16' = 16,

	'half' = 2,
	'quarter' = 4,
	'eighth' = 8,
	'sixteenth' = 16,
}