export interface PebbleSizeDescription {
	display: string,
	maxSize: number,
	/**
	 * Tons per cubic feet
	 */
	weightFactor: number,

}
export default abstract class PebbleSizeProvider {
	abstract getPebbleSizes(): PebbleSizeDescription[]
}