import { Label, makeStyles, mergeClasses, useId, Text, Input } from "@fluentui/react-components";
import { useContext, useEffect } from "react";
import { MaterialsContext } from "../../../../services/Context/MaterialContext";
import { StackedTerrainValues, TerrainType } from "../../../../services/Context/MaterialContext/FieldTypes";
import ImperialLengthInput from "../../../Shared/ImperialLengthInput";
import ITerrainsProps from "./Shared/ITerrainsProps";

const useStyles = makeStyles({
	avgWidth: {
	},
	avgHeight: {
	},
	avgLength: {
	},
	coverage: {
	},
});

function StackedTerrain(props: ITerrainsProps) {
	const classes = useStyles();

	const materialContext = useContext(MaterialsContext);
	const terrainValues = materialContext?.terrainValue as StackedTerrainValues;

	const defaultCoverage = 15;

	const avgWidthId = useId("avgWidth");
	const avgHeightId = useId("avgHeight");
	const avgLengthId = useId("avgLength");
	const coverageId = useId("coverage");

	useEffect(() => {
		if (materialContext?.terrainType !== TerrainType.Stacked) {
			materialContext?.setTerrainType(TerrainType.Stacked);
			materialContext?.setTerrainValue({ ...new StackedTerrainValues(), coverage: defaultCoverage });
		}
		else {
			materialContext?.setTerrainValue(previous => ({ ...previous }));
		}
	}, []);

	useEffect(() => {
		const canCalculate = terrainValues.avgWidth > 0 && terrainValues.avgHeight > 0 && terrainValues.avgLength > 0 && terrainValues.coverage > 0;
		props.onCanCalculateMaterialsChanged(canCalculate);
	}, [props.onCanCalculateMaterialsChanged, terrainValues.avgWidth, terrainValues.avgHeight, terrainValues.avgLength, terrainValues.coverage]);

	const setAvgWidthParamsContext = (value: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, avgWidth: value }));
	};
	const setAvgHeightParamsContext = (value: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, avgHeight: value }));
	};
	const setAvgLengthParamsContext = (value: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, avgLength: value }));
	};
	const setCoverageParamsContext = (value: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, coverage: value }));
	};

	return (
		<>
			<div className={mergeClasses("form-input-container", classes.avgWidth)}>
				<Label htmlFor={avgWidthId} weight="semibold" className="form-input-label">Avg. Width</Label>
				<ImperialLengthInput id={avgWidthId}
					className="form-input"
					value={terrainValues.avgWidth}
					onValueChange={setAvgWidthParamsContext}
					unit='ft' />
			</div>
			<div className={mergeClasses("form-input-container", classes.avgHeight)}>
				<Label htmlFor={avgHeightId} weight="semibold" className="form-input-label">Avg. Height</Label>
				<ImperialLengthInput id={avgHeightId}
					className="form-input"
					value={terrainValues.avgHeight}
					onValueChange={setAvgHeightParamsContext}
					unit='ft' />
			</div>
			<div className={mergeClasses("form-input-container", classes.avgLength)}>
				<Label htmlFor={avgLengthId} weight="semibold" className="form-input-label">Avg. Length</Label>
				<ImperialLengthInput id={avgLengthId}
					className="form-input"
					value={terrainValues.avgLength}
					onValueChange={setAvgLengthParamsContext}
					unit='ft' />
			</div>
			<div className={mergeClasses("form-input-container", classes.coverage)} style={{ display: 'none' }}>
				<Label htmlFor={coverageId} weight="semibold" className="form-input-label">Coverage by Ton</Label>
				<Input type="number"
					className="form-input"
					value={terrainValues.coverage?.toString()}
					onChange={value => setCoverageParamsContext(value.target.valueAsNumber)}
					contentAfter={<Text>cu. ft.</Text>}
					id={coverageId} />
			</div>
		</>
	);
}

export default StackedTerrain;