import { Dropdown, Option, makeStyles, mergeClasses, shorthands, Title3, tokens, Subtitle1, Subtitle2 } from '@fluentui/react-components';
import { useCallback, useContext, useMemo } from 'react';
import { MaterialsContext } from '../../services/Context/MaterialContext';
import { MaterialResult } from '../../services/Context/MaterialContext/FieldTypes';
import breakpoints from '../../styles/designBreakpoints';
import { OptionOnSelectData } from '../Shared/Models/OptionOnSelectData';

class HeaderProps {
	className?: string = '';
}

const useStyles = makeStyles({
	root: {
		backgroundColor: tokens.colorBrandBackground,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		...shorthands.padding(tokens.spacingVerticalMNudge, tokens.spacingHorizontalXL),
	},
	logoSection: {
		display: 'flex',
		alignItems: 'center',
		...shorthands.gap(tokens.spacingHorizontalXL)
	},
	titleNarrow: {
		color: tokens.colorNeutralForegroundOnBrand,
		display: 'unset',
		...breakpoints.m({
			display: 'none',
		})
	},
	titleWide: {
		color: tokens.colorNeutralForegroundOnBrand,
		display: 'none',
		...breakpoints.m({
			display: 'unset',
		})
	},
	resultTypeSelector: {
		...shorthands.borderColor(tokens.colorNeutralForegroundOnBrand),
		color: tokens.colorNeutralForegroundOnBrand,
		"::after": {
			...shorthands.borderColor(tokens.colorNeutralForegroundOnBrand)
		},
		":focus-within": {
			":active": {
				"::after": {
					...shorthands.borderColor(tokens.colorNeutralForegroundOnBrand)
				}
			}
		}
	},
	resultTypeSelectorIcon: {
		color: tokens.colorNeutralForegroundOnBrand
	},
	resultTypeSelectorButton: {
		...shorthands.borderColor(tokens.colorNeutralForegroundOnBrand),
		color: tokens.colorNeutralForegroundOnBrand
	}
});

function Header(props: HeaderProps) {
	const classes = useStyles();
	const materialContext = useContext(MaterialsContext);

	const resultOptions = useMemo(() => [{ key: "packaged", display: "Bags" }, { key: "raw", display: "Tons" }], [])
	const selectedResult = useMemo(() => resultOptions.find(o => o.key === materialContext?.materialsResult), [resultOptions, materialContext?.materialsResult])

	const changeResultOption = useCallback((_: any, data: OptionOnSelectData) => {
		materialContext?.setMaterialsResult(data.optionValue as MaterialResult)
	}, [])

	return (
		<div className={mergeClasses(classes.root, props.className /* these definitions have higher precedence */)} >
			<div className={ classes.logoSection }>
				<img src="/logo.png" alt="Company logo" style={{ maxHeight: 40 }} />
				<Title3 className={classes.titleWide}>Material Calculator</Title3>
			</div>
			<Subtitle2 className={classes.titleNarrow}>Material Calculator</Subtitle2>
			<div>
				<Dropdown appearance="underline" style={{ minWidth: "4em" }}
					className={classes.resultTypeSelector}
					expandIcon={{ className: classes.resultTypeSelectorIcon }}
					button={{ className: classes.resultTypeSelectorButton }}
					onOptionSelect={changeResultOption}
					value={selectedResult?.display}
					selectedOptions={selectedResult ? [selectedResult.key] : []}
					listbox={{ style: { minWidth: "4em" } }}
				>
					{
						resultOptions.map(o => <Option key={o.key} value={o.key}>{o.display}</Option>)
					}
				</Dropdown>
			</div>
		</div>
	);
}
export default Header;