import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { MaterialResult, Terrain, TerrainType } from "./FieldTypes";

export interface MaterialsContextType {
	terrainValue: Terrain | {}
	setTerrainValue: Dispatch<SetStateAction<Terrain | {}>>,
	clearTerrain: () => void,
	terrainType: TerrainType | null,
	setTerrainType: Dispatch<SetStateAction<TerrainType | null>>,
	materialsResult: MaterialResult
	setMaterialsResult: Dispatch<SetStateAction<MaterialResult>>
}

interface IMaterialsProviderProps {
	children?: JSX.Element
}

export const MaterialsContext = createContext<MaterialsContextType | null>(null);

export default function MaterialsProvider(props: IMaterialsProviderProps) {
	const [terrainValue, setTerrainValue] = useState<Terrain | {}>({});
	const [terrainType, setTerrainType] = useState<TerrainType | null>(null);
	const [materialsResult, setMaterialsResult] = useState<MaterialResult>('packaged');

	const clearTerrain = useCallback(() => {
		setTerrainValue({});
		setTerrainType(null);
	}, []);

	const value: MaterialsContextType = useMemo(() => {
		const value: MaterialsContextType =
		{
			terrainValue,
			setTerrainValue,
			clearTerrain,
			terrainType,
			setTerrainType,
			materialsResult,
			setMaterialsResult
		}
		return value;
	}, [clearTerrain, terrainType, terrainValue, materialsResult]);

	return (
		<MaterialsContext.Provider value={value}>
			{props.children}
		</MaterialsContext.Provider>
	)
}