import FillTypeProvider, { IFillType } from ".";

class MemoryFillTypeProvider extends FillTypeProvider {
	getFillTypes(): string[] {
		return Object.keys(this.fillTypes);
	}
	getAvailableFillTypes(): { [key: string]: IFillType } {
		return this.fillTypes;
	}
}

export default MemoryFillTypeProvider;