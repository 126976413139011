import { InchFractionPrecision } from "./InchFractionPrecision";

const inchFractions = [
	{ value: 0, label: '0' },
	{ value: 1 / 16, label: '1/16' },
	{ value: 1 / 8, label: '1/8' },
	{ value: 3 / 16, label: '3/16' },
	{ value: 1 / 4, label: '1/4' },
	{ value: 5 / 16, label: '5/16' },
	{ value: 3 / 8, label: '3/8' },
	{ value: 7 / 16, label: '7/16' },
	{ value: 1 / 2, label: '1/2' },
	{ value: 9 / 16, label: '9/16' },
	{ value: 5 / 8, label: '5/8' },
	{ value: 11 / 16, label: '11/16' },
	{ value: 3 / 4, label: '3/4' },
	{ value: 13 / 16, label: '13/16' },
	{ value: 7 / 8, label: '7/8' },
	{ value: 15 / 16, label: '15/16' },
	{ value: 1, label: '0' },
];

export function getInchFractionLabel(inchFraction: number, precision: InchFractionPrecision): string {
	let fraction = inchFractions.find(f => f.value === inchFraction);
	if (!fraction) {
		fraction = inchFractions.find(f => f.value === Math.round(inchFraction * precision) / precision);
		if (!fraction) {
			return '';
		}
	}

	return `${fraction.label}"`;
}