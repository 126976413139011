import AreaProvider from "./AreaProvider";

export default class MemoryAreaProvider extends AreaProvider {
	getAreas(): number[] {
		return [
			25,
			50,
			75,
			100,
			200
		]
	}
}