import { useContext, useMemo } from "react";
import { MaterialsContext } from "../../../services/Context/MaterialContext";
import { LandscapeTerrainValues } from "../../../services/Context/MaterialContext/FieldTypes";
import LandscapeMaterialsCalculator from "../../../services/Services/TerrainMaterialsCalculator/LandscapeTerrain";
import { DependencyInjectionContext } from "../../../services/DependencyInjectionProvider";
import { calcRequiredBags } from "../../../utils/RequiredMaterials";
import RequiredMaterials from "./RequiredMaterials";

export interface ILandscapeRequiredMaterialsProps {
	onRestartCalcs: () => void;
	onEditCalcs: () => void;
}

export default function LandscapeRequiredMaterials(props: ILandscapeRequiredMaterialsProps) {
	const materialContext = useContext(MaterialsContext);
	const dependencyInjectionContext = useContext(DependencyInjectionContext);

	const landscapeTerrainValues = materialContext?.terrainValue as LandscapeTerrainValues;
	const weightFactor = (materialContext?.terrainValue as LandscapeTerrainValues).weightFactor;

	const bagsProvider = dependencyInjectionContext?.bagsProvider;

	const availablesBags = useMemo(() => bagsProvider?.getBagsTypes() ?? [], [bagsProvider]);
	const landscapeMaterialCalculator = useMemo(() => {
		return new LandscapeMaterialsCalculator({ area: landscapeTerrainValues.area, depth: landscapeTerrainValues.depth, weightFactor: landscapeTerrainValues.weightFactor });
	}, [landscapeTerrainValues.area, landscapeTerrainValues.depth, landscapeTerrainValues.weightFactor]);

	const productImage = useMemo(() => {
		switch (landscapeTerrainValues.fillType) {
			case "gravel":
				if (!landscapeTerrainValues.pebbleDescription)
					return "/materials/pebble/Mixed-Pebble-One-to-Two-Inch-1024x768.jpg";
				if (landscapeTerrainValues.pebbleDescription?.maxSize > 3)
					return "/materials/pebble/Buff-Pebble-three-to-five-inch-1024x768.jpg";
				else if (landscapeTerrainValues.pebbleDescription?.maxSize > 2)
					return "/materials/pebble/Mixed-Pebble-Two-to-Three-Inch-1024x768.jpg";
				else if (landscapeTerrainValues.pebbleDescription?.maxSize > 1)
					return "/materials/pebble/Mixed-Pebble-One-to-Two-Inch-1024x768.jpg";
				else if (landscapeTerrainValues.pebbleDescription?.maxSize > 5 / 8)
					return "/materials/pebble/Mixed-Pebble-Half-to-One-Inch-1024x768.jpg";
				else if (landscapeTerrainValues.pebbleDescription?.maxSize <= 5 / 8)
					return "/materials/pebble/Mixed-Pebble-three-eights-inch-1024x768.jpg";
				return "/materials/pebble/Mixed-Pebble-One-to-Two-Inch-1024x768.jpg";
			case "sand":
				return "/materials/sand/Sand.jpg";
			case "soil":
				return "/materials/soil/soil-composition.webp";
		}
		return "not-supported";
	}, [landscapeTerrainValues.fillType, landscapeTerrainValues.pebbleDescription?.maxSize]);

	const materialDescription = useMemo(() => {
		switch (landscapeTerrainValues.fillType) {
			case "gravel":
				if (!landscapeTerrainValues.pebbleDescription)
					return "gravel";
				return `${landscapeTerrainValues.pebbleDescription?.display} pebbles`;
			case "sand":
				return "sand";
			case "soil":
				return "soil";
		}
		return "material";
	}, [landscapeTerrainValues.fillType, landscapeTerrainValues.pebbleDescription?.display]);

	return <>
		<RequiredMaterials style={{ maxWidth: 1024, margin: 'auto' }}
			depth={landscapeTerrainValues.depth * 12} materialDescription={materialDescription} surfaceArea={landscapeTerrainValues.area} requiredMaterialQuantity={landscapeMaterialCalculator.calculate().value}
			imgSrc={productImage}
			format={{
				surfaceArea: v => v.toFixed(1) + " sq.\u00A0ft.",
				depth: v => v + "''",
				quantity: v => {

					if (materialContext?.materialsResult === "raw")
						return (v / 2000).toFixed(2) + " tons";
					if (weightFactor) {
						const requiredBags = calcRequiredBags(availablesBags, v);
						return requiredBags.map(b => `${b?.count} bags (${b.bag.display} each)`).join(", ");
					}
					return v.toFixed(2) + " cu.\u00A0ft.";
				}
			}}
			onRestartCalcs={props.onRestartCalcs}
			onEditCalcs={props.onEditCalcs}
		/>
	</>
}