import { useMemo } from 'react';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    Subtitle1,
    TableCellLayout,
    Body1,
    Body1Strong,
    Subtitle2
} from '@fluentui/react-components';

const useStyles = makeStyles({
    root: {
        borderCollapse: "separate",
        backgroundColor: tokens.colorBrandBackground,
    },
    headerColumn: {
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralForegroundOnBrand
    },
    headerColumnContent: {
        width: "100%"
    },
    headerRow: {
        backgroundColor: tokens.colorNeutralBackground6,
        color: tokens.colorNeutralForeground1
    },
    bodyCell: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorNeutralForeground1,
    },
    bodyCellContent: {
        width: "100%",
        display: "inline-block",
        whiteSpace: "pre-line"
    },
    cell: {
        ...shorthands.border("1px", "solid", tokens.colorNeutralBackground1)
    }
});
type TableFormatter = {
    columnHeader?: (v: number) => string,
    rowHeader?: (v: number) => string,
    body?: (v: number) => string
}
type TableSafeFormatter = {
    columnHeader: (v: number) => string,
    rowHeader: (v: number) => string,
    body: (v: number) => string
}
export interface IMaterialCoverageTableProps {
    depths: number[],
    areas: number[],
    calculateValue: (depth: number, area: number) => number,
    format?: TableFormatter
}

export default function MaterialCoverageTable(props: IMaterialCoverageTableProps) {
    const format: TableSafeFormatter = useMemo(() => {
        let formatter = props.format ?? {} as TableFormatter;
        if (!formatter.columnHeader)
            formatter.columnHeader = (v: number) => v.toString();
        if (!formatter.rowHeader)
            formatter.rowHeader = (v: number) => v.toString();
        if (!formatter.body)
            formatter.body = (v: number) => v.toString();

        return formatter as TableSafeFormatter;
    }, [props.format]);


    const classes = useStyles();
    return (
        <Table className={classes.root}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell rowSpan={2} className={mergeClasses(classes.headerRow, classes.cell)}>
                        <Subtitle2>Depth</Subtitle2>
                    </TableHeaderCell>
                    <TableHeaderCell colSpan={props.areas.length} className={mergeClasses(classes.headerColumn, classes.cell)}>
                        <Subtitle1 align="center" className={classes.headerColumnContent}>Area</Subtitle1>
                    </TableHeaderCell>
                </TableRow>
                <TableRow>
                    {
                        props.areas.map((a, i) => (
                            <TableHeaderCell key={i} className={mergeClasses(classes.headerColumn, classes.cell)}>
                                <Body1Strong align="center" className={classes.headerColumnContent}>{format.columnHeader(a)}</Body1Strong>
                            </TableHeaderCell>
                        ))
                    }
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    props.depths.map((d, i) => (
                        <TableRow key={i}>
                            <TableCell className={mergeClasses(classes.headerRow, classes.cell)}>
                                <TableCellLayout appearance="primary">
                                    {format.rowHeader(d)}
                                </TableCellLayout>
                            </TableCell>
                            {
                                props.areas.map((a, i) => (
                                    <TableCell key={i} className={mergeClasses(classes.bodyCell, classes.cell)}>
                                        <Body1 align="center" className={classes.bodyCellContent}>{format.body(props.calculateValue(d, a))}</Body1>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
};
