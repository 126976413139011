import { BagType } from "../services/Providers/BagsProvider";

export function calcRequiredBags(availablesBags: BagType[], requiredMaterialsWeight: number) {
	let recomendedBags: { bag: BagType, count: number }[] = [];
	if (availablesBags != null) {
		let sortedBags = availablesBags.sort((a, b) => b.weight - a.weight);
		let remainingWeight = requiredMaterialsWeight;

		for (let bag of sortedBags) {
			if (bag.weight < remainingWeight) {
				let bagsCount = Math.floor(remainingWeight / bag.weight);
				recomendedBags.push({ bag: bag, count: bagsCount });
				remainingWeight -= bagsCount * bag.weight;
			}

			if (remainingWeight === 0) {
				break;
			}
			//poner otra bolsa
		}
		if (remainingWeight > 0) {
			if (recomendedBags.length > 0 && recomendedBags[recomendedBags.length - 1].bag === sortedBags[sortedBags.length - 1]) {
				recomendedBags[recomendedBags.length - 1].count += 1;
			} else {
				recomendedBags.push({ bag: sortedBags[sortedBags.length - 1], count: 1 });
			}
		}
	}
	return recomendedBags;
}