import { useCallback, useContext, useMemo, useState } from 'react';
import './style.css';
import '../../../styles/styles.css'
import { makeStyles, mergeClasses, useId, Option, Label, shorthands, Divider, Button, Dropdown } from '@fluentui/react-components';
import { Calculator24Regular } from "@fluentui/react-icons";
import { DependencyInjectionContext } from '../../../services/DependencyInjectionProvider';
import { OptionOnSelectData } from '../../Shared/Models/OptionOnSelectData';
import { MaterialsContext } from '../../../services/Context/MaterialContext';

export interface TerrainInputProps {
	className?: string,
	onCalculate: () => void
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		...shorthands.gap('1em')
	},
	terrainTypeContainer: {
	},
	terrainTypeCombobox: {
	},
	actionButton: {
		width: "100%",
		maxWidth: "100%"
	}
});

function TerrainInput(props: TerrainInputProps) {
	const comboId = useId("terrainType")
	const classes = useStyles();

	const dependencyInjectionContext = useContext(DependencyInjectionContext);

	const terrainDistributionProvider = dependencyInjectionContext?.terrainDistributionProvider;

	const availableTerrainDistributions = useMemo(() => terrainDistributionProvider?.getAvailableTerrainDistributions(), [terrainDistributionProvider]);
	const terrainDistributions = useMemo(() => terrainDistributionProvider?.getTerrainDistributions() ?? [], [terrainDistributionProvider]);

	const [selectedTerrain, setSelectedTerrain] = useState<string>(terrainDistributions[0]);
	const [canCalculateMaterials, setCanCalculateMaterials] = useState<boolean>(false);

	const renderAvailableTerrainDistributions = useMemo(() => {
		return terrainDistributions.map((key) =>
			<Option key={key} value={key}>{availableTerrainDistributions![key].display}</Option>
		)
	}, [terrainDistributions, availableTerrainDistributions]);

	const changeTerrainDistribution = useCallback((e: any, data: OptionOnSelectData) => {
		setSelectedTerrain(data?.optionValue!);
	}, []);

	const terrainInput = useMemo(() => {
		if (selectedTerrain != null && availableTerrainDistributions != null) {
			const Component = availableTerrainDistributions[selectedTerrain].component;
			return <Component onCanCalculateMaterialsChanged={setCanCalculateMaterials} />
		}
	}, [selectedTerrain, availableTerrainDistributions])

	return (
		<div className={mergeClasses(classes.root, props.className /* these definitions have higher precedence */)} >
			{
				terrainDistributions.length > 1 &&
				<div className={mergeClasses("form-input-container", classes.terrainTypeContainer)}>
					<Label id={comboId} weight="semibold" className="form-input-label">Terrain Type</Label>
					<Dropdown
						aria-labelledby={comboId}
						placeholder="Select a terrain"
						onOptionSelect={changeTerrainDistribution}
						className={mergeClasses("form-input", classes.terrainTypeCombobox)}
						defaultValue={availableTerrainDistributions![terrainDistributions[0]].display}
					>
						{renderAvailableTerrainDistributions}
						</Dropdown>
				</div>
			}
			{terrainInput}
			<Divider></Divider>
			<Button appearance="primary" className={classes.actionButton}
				disabled={!canCalculateMaterials}
				icon={<Calculator24Regular />}
				onClick={props.onCalculate}
			>
				Calculate materials
			</Button>
		</div>
	);
}

export default TerrainInput;