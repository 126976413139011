import { Button, Caption1, Card, CardFooter, CardPreview, Subtitle1, tokens, makeStyles, CardHeader } from "@fluentui/react-components"
import { ArrowReplyRegular, Edit24Regular } from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";

const useStyles = makeStyles({
    materialQuantity: {
        color: tokens.colorBrandBackgroundHover
    }
});

type RequiredMaterialsFormatter = {
	quantity?: (q: number) => string,
	surfaceArea?: (q: number) => string,
	depth?: (q: number) => string
}

type RequiredMaterialsSafeFormatter = {
	quantity: (q: number) => string,
	surfaceArea: (q: number) => string,
	depth: (q: number) => string
}

export interface IRequiredMaterialsProps {
	imgSrc: string,
	requiredMaterialQuantity: number,
	materialDescription: string,
	surfaceArea: number,
	depth?: number,
	format?: RequiredMaterialsFormatter,
	onRestartCalcs: () => void,
	onEditCalcs: () => void,

	className?: string,
	style?: React.CSSProperties
}

function shouldBeHorizontal() {
	return window.innerHeight <= 600 && window.innerHeight / window.innerWidth <= 0.9
}
export default function RequiredMaterials(props: IRequiredMaterialsProps) {
	const [cardOrientation, setCardOrientation] = useState<"horizontal" | "vertical">(shouldBeHorizontal() ? "horizontal" : "vertical");
	const styles = useStyles();
	const format = useMemo(() => {
		let formatter = props.format ?? {} as RequiredMaterialsFormatter;
		if (!formatter.quantity)
			formatter.quantity = v => v.toString();
		if (!formatter.surfaceArea)
			formatter.surfaceArea = v => v.toString();
		if (!formatter.depth)
			formatter.depth = v => v.toString();

		return formatter as RequiredMaterialsSafeFormatter;
	}, [props.format]);

	useEffect(() => {
		const onResize = () => {
			setCardOrientation(shouldBeHorizontal() ? "horizontal" : "vertical");
		};

		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const CardContent = () => <>
		<CardHeader
			header={<Subtitle1>You'll need <b className={styles.materialQuantity}>{format.quantity(props.requiredMaterialQuantity)}</b> of {props.materialDescription} to cover {format.surfaceArea(props.surfaceArea)}{props.depth ? ` at a depth of ${format.depth(props.depth)}` : ""}.</Subtitle1>}
			description={<Caption1>All coverages are approximate due to natural variations in stone size, shape and weight. Be sure to review the information with a member of our team before placing your material order.</Caption1>}
		/>
		<CardFooter>
			<Button appearance="primary"
				icon={<ArrowReplyRegular />}
				aria-label="Start over"
				onClick={props.onRestartCalcs}
			>
				Start Over
			</Button>
			<Button
				icon={<Edit24Regular />}
				aria-label="Edit calculations"
				onClick={props.onEditCalcs}
			>
				Edit Calculations
			</Button>
		</CardFooter>
	</>;

	return (
		<Card appearance="subtle" className={props.className} style={props.style} orientation={cardOrientation}>
			<CardPreview style={{ width: cardOrientation === "horizontal" ? "75%" : undefined }}>
				<img src={props.imgSrc} alt={props.materialDescription} style={{ maxHeight: 768 }} />
			</CardPreview>
			{
				cardOrientation === "horizontal" &&
				<div style={{ display: 'flex', flexDirection: 'column', gap: tokens.spacingVerticalM }}>
						<CardContent />
				</div>
			}
			{
				cardOrientation !== "horizontal" &&
				<CardContent/>
			}
		</Card>
	)
}