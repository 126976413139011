import { Input, Label, makeStyles, mergeClasses, useId, Text, InputProps } from "@fluentui/react-components";
import { useCallback, useContext, useEffect } from "react";
import ITerrainsProps from "./Shared/ITerrainsProps";
import ShapeInput, { AreaCalculationType, AreaType } from "./Shared/ShapeInput";
import '../../../../styles/styles.css'
import { MaterialsContext } from "../../../../services/Context/MaterialContext";
import { FlatworkTerrainValues, TerrainType } from "../../../../services/Context/MaterialContext/FieldTypes";

const useStyles = makeStyles({
	root: {
	},
	coverageInputContainer: {
	}
});

function FlatworkTerrain(props: ITerrainsProps) {
	const classes = useStyles();

	const materialContext = useContext(MaterialsContext);
	const terrainValues = materialContext?.terrainValue as FlatworkTerrainValues;

	const defaultCoverage = 120;

	const coverageId = useId("coverage");

	useEffect(() => {
		props.onCanCalculateMaterialsChanged(terrainValues.area > 0 && terrainValues.coverage > 0)
	}, [props.onCanCalculateMaterialsChanged, terrainValues.coverage, terrainValues.area]);

	useEffect(() => {
		if (materialContext?.terrainType !== TerrainType.Flatwork) {
			materialContext?.setTerrainType(TerrainType.Flatwork);
			materialContext?.setTerrainValue({ ...new FlatworkTerrainValues(), coverage: defaultCoverage });
		}
		else {
			materialContext?.setTerrainValue(previous => ({ ...previous }));
		}
	}, []);

	const onChange: InputProps["onChange"] = (ev, data) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, coverage: Number(data.value) }));
	};

	const changeArea = useCallback((area: number) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, area: area / 144 }));
	}, []);

	const changeShape = useCallback((shape: AreaType) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, shapeType: shape }));
	}, []);

	const changeShapeParams = useCallback((params: number[]) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, shapeValue: params }));
	}, []);

	const changeAreaMode = useCallback((mode: AreaCalculationType) => {
		materialContext?.setTerrainValue(previous => ({ ...previous, areaCalculationMode: mode }));
	}, []);

	return (
		<>
			<div className={mergeClasses("form-input-container", classes.coverageInputContainer)} style={{display: 'none'}}>
				<Label htmlFor={coverageId} weight="semibold" className="form-input-label">Coverage by Ton</Label>
				<Input type="number"
					id={coverageId}
					className="form-input"
					value={terrainValues.coverage?.toString()}
					onChange={onChange}
					contentAfter={<Text>sq. ft.</Text>} />
			</div>
			<ShapeInput
				calculationType={terrainValues.areaCalculationMode}
				shapeParams={terrainValues.shapeValue}
				shape={terrainValues.shapeType}
				onCalculationTypeChange={changeAreaMode}
				onShapeChange={changeShape}
				onShapeParamsChange={changeShapeParams}
				onAreaChange={changeArea} />
		</>
	);
}

export default FlatworkTerrain;