import React, { useContext, useEffect } from 'react';
import './style.css';
import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';
import { useMotion } from "@fluentui/react-motion-preview";
import TerrainInput from './TerrainInput';
import breakpoints from '../../styles/designBreakpoints';
import { TITLE } from '../../config/constants';
import { MaterialsContext } from '../../services/Context/MaterialContext';
import { TerrainType } from '../../services/Context/MaterialContext/FieldTypes';
import LandscapeMaterialTable from './TerrainMaterialTable/LandscapeMaterialTable';
import LandscapeRequiredMaterials from './RequiredMaterialsV2/LandscapeRequiredMaterials';

class MaterialCalculatorContentProps {
	className?: string = '';
}

const useStyles = makeStyles({
	root: {
		...shorthands.margin('auto')
	},
	flexContainer: {
		width: 'fit-content',
		display: 'grid',
		gridTemplateColumns: 'auto',
		...shorthands.margin('auto'),
		...shorthands.gap(tokens.spacingHorizontalL, tokens.spacingVerticalL),
		...breakpoints.l({
			gridTemplateColumns: 'auto auto',
		})
	},
	sectionCard: {
		maxWidth: '650px',
		boxShadow: tokens.shadow4,
		...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),
		backgroundColor: tokens.colorNeutralBackground1,
		...shorthands.borderRadius(tokens.borderRadiusMedium),
	},
	terrainInputContainer: {
		height: 'fit-content',
		...breakpoints.l({
			minWidth: '450px',
			maxWidth: '450px',
		})
	},
	terrainInput: {
	},
	materialsTableContainer: {
		backgroundColor: tokens.colorNeutralBackground2,
		height: 'fit-content',
		...breakpoints.l({
			minWidth: '380px'
		})
	},
	requiredMaterialsMotionNotVisible: {
		opacity: 0,
		transform: "translate3D(0, 0, 0) scale(0.50)",
		transitionDuration: `${tokens.durationFast}, ${tokens.durationGentle}`,
		transitionProperty: "opacity, transform",
		willChange: "opacity, transform",
	},
	requiredMaterialsMotionVisible: {
		opacity: 1,
		transform: "translate3D(0, 0, 0) scale(1)",
	},
	overlappingParent: {
		display: 'grid',
	},
	overlappingChild: {
		...shorthands.gridArea(1, 1, 2, 2)
	}
});

function MaterialCalculatorContent(props: MaterialCalculatorContentProps) {
	const classes = useStyles();
	const materialContext = useContext(MaterialsContext);

	const [showRequiredMaterials, setShowRequiredMaterials] = React.useState(false);

	useEffect(() => {
		document.title = `Material Calculator - ${TITLE}`;
	}, []);

	const materialsMotion = useMotion<HTMLDivElement>(showRequiredMaterials);
	const inputMotion = useMotion<HTMLDivElement>(!showRequiredMaterials);

	return (
		<div className={mergeClasses(classes.root, props.className /* these definitions have higher precedence */)} >
			<div className={classes.flexContainer}>
				<div className={mergeClasses(classes.terrainInputContainer, classes.sectionCard, classes.overlappingParent)}>
					{
						inputMotion.canRender &&
						<div ref={inputMotion.ref} className={mergeClasses(classes.requiredMaterialsMotionNotVisible, inputMotion.active && classes.requiredMaterialsMotionVisible, classes.overlappingChild)}>
							<TerrainInput className={classes.terrainInput} onCalculate={() => setShowRequiredMaterials(true)} />
						</div>
					}
					{
						materialsMotion.canRender &&
						<div ref={materialsMotion.ref} className={mergeClasses(classes.requiredMaterialsMotionNotVisible, materialsMotion.active && classes.requiredMaterialsMotionVisible, classes.overlappingChild)}>
							{
								materialContext?.terrainType === TerrainType.Landscape &&
								<LandscapeRequiredMaterials
									onRestartCalcs={() => {
										console.log("Requesting clean");
										materialContext?.clearTerrain();
										setShowRequiredMaterials(false);
									}}
									onEditCalcs={() => setShowRequiredMaterials(false)}
								/>
							}
						</div>
					}
				</div>
				<div className={mergeClasses(classes.materialsTableContainer, classes.sectionCard)}>
					{
						materialContext?.terrainType === TerrainType.Landscape && <LandscapeMaterialTable />
					}
				</div>
			</div>
		</div>
	);
}

export default MaterialCalculatorContent;