import FlatworkTerrain from "../../../components/MaterialCalculatorContent/TerrainInput/Terrains/FlatworkTerrain";
import ITerrainsProps from "../../../components/MaterialCalculatorContent/TerrainInput/Terrains/Shared/ITerrainsProps";
import LandscapeTerrain from "../../../components/MaterialCalculatorContent/TerrainInput/Terrains/LandscapeTerrain";
import StackedTerrain from "../../../components/MaterialCalculatorContent/TerrainInput/Terrains/StackedTerrain";

export interface ITerrainDistributions {
	[key: string]: { display: string, component: (props: ITerrainsProps) => JSX.Element };
}
abstract class TerrainDistributionProvider {
	protected terrainDistributions: ITerrainDistributions = {
		landscape: {
			display: "Landscape bed",
			component: LandscapeTerrain
		},
		flatwork: {
			display: "Flatwork",
			component: FlatworkTerrain
		},
		stacked: {
			display: "Stacked Stone & Waterfall",
			component: StackedTerrain
		}
	}
	abstract getTerrainDistributions(): string[]
	abstract getAvailableTerrainDistributions(): ITerrainDistributions
}

export default TerrainDistributionProvider;