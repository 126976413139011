import PebbleSizeProvider, { PebbleSizeDescription } from "./PebbleSizeProvider"

export default class MemoryPebbleSizeProvider extends PebbleSizeProvider {
	getPebbleSizes(): PebbleSizeDescription[] {
		return [
			{
				display: "3/8'' - 5/8''",
				maxSize: 5 / 8,
				weightFactor: 1 / 16.67
			},
			{
				display: "1/2'' - 1''",
				maxSize: 1,
				weightFactor: 1 / 16.67
			},
			{
				display: "1'' - 2''",
				maxSize: 2,
				weightFactor: 1 / 16.67
			},
			{
				display: "2'' - 3''",
				maxSize: 3,
				weightFactor: 1 / 13.33
			},
			{
				display: "3'' - 5''",
				maxSize: 5,
				weightFactor: 1 / 10
			},
			{
				display: "5'' - 10''",
				maxSize: 10,
				weightFactor: 1 / 6.67
			}
		]
	}
}